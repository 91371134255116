import { useState, useEffect, Fragment, useRef } from 'react';
import classnames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import { useMediaQuery } from '@mui/material';

import styles from './Process.module.scss';

import Button from '@components/shared/Button/Button';
import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import theme from '@styles/theme';
import { Video } from '@components/shared/Video/Video';
import { PROCESS_OPTIONS } from '@constants/homepage/HowItWorksV1';
import routes from '@constants/routes';
import LoadingImg from '@public/img/homepage/howItWorks/loadingImg.jpg';
import Placeholder from '@public/img/homepage/howItWorks/placeholder.png';
import { elementClicked, elementViewed } from '@utils/sendEvent';

export function Process() {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
  const { setNodeRef, isVisible } = useIntersectionObserver(
    {
      threshold: isExtraLarge ? 0.9 : 0.25
    },
    false,
    true
  );
  const [selectedOption, setSelectedOption] = useState(0);
  const [previousOption, setPreviousOption] = useState(-1);
  const [isAutoAdvancing, setIsAutoAdvancing] = useState(false);
  const countdownRef1 = useRef<NodeJS.Timer>();
  const countdownRef2 = useRef<NodeJS.Timer>();

  const handleClick = (i: number, h3: string) => () => {
    // This check lets the user hide the selected option on mobile
    if (isSmall && window.innerWidth < 600 && selectedOption === i) {
      setSelectedOption(-1);
    } else {
      setPreviousOption(selectedOption);
      setSelectedOption(i);
    }
    elementClicked({
      actionOutcome: 'TOGGLE',
      outboundUrl: 'null',
      webElement: {
        elementType: 'button',
        location: 'homepage',
        name: `Step ${i + 1}`,
        position: 'HOWITWORKS V2',
        text: h3
      }
    });
  };

  const handleAutoAdvance = () => {
    // Update the selected and previous option on each auto advance
    setSelectedOption((i) => i + 1);
    setPreviousOption((i) => i + 1);
  };

  const startAutoAdvance = () => {
    // Start the counter and set the state
    countdownRef1.current = setTimeout(handleAutoAdvance, 5000);
    countdownRef2.current = setTimeout(handleAutoAdvance, 10000);
    setIsAutoAdvancing(true);
  };

  const stopAutoAdvance = () => {
    // Clear the  counter and update the state
    clearTimeout(countdownRef1.current);
    clearTimeout(countdownRef2.current);
    setIsAutoAdvancing(false);
  };

  useEffect(() => {
    // Start autoadvancing if the component is visible
    // If not, stop autoadvancing
    if (isVisible) {
      startAutoAdvance();
      elementViewed({
        webElement: {
          elementType: 'Button',
          location: 'Homepage',
          name: 'Step 1',
          position: 'HOWITWORKS V2',
          text: 'Connect with a Local Expert'
        }
      });
    } else {
      stopAutoAdvance();
    }
    return () => stopAutoAdvance();
  }, [isVisible]);

  return (
    <div
      className={styles['process-container']}
      onPointerDown={() => {
        //Stop auto advance on user interaction
        stopAutoAdvance();
      }}
      ref={setNodeRef}
    >
      <h2 className={styles['process-header']}>How it works</h2>
      <div className={styles['panel-containers']}>
        <div className={styles['top-panel']}>
          <div className={styles['tabs-container']} role="tab-panel">
            {PROCESS_OPTIONS.map((option, i) => (
              <Fragment key={option.h3}>
                <button
                  type="button"
                  role="tab"
                  className={classnames(styles['tab-button'], {
                    [styles['tab-button-active']]: selectedOption === i
                  })}
                  onClick={handleClick(i, option.h3)}
                >
                  {selectedOption === i && !isSmall && (
                    <div
                      className={classnames(styles['tab-button-swipe'], {
                        [styles['tab-button-swipe--right-1']]: selectedOption - previousOption === 1,
                        [styles['tab-button-swipe--left-1']]: previousOption - selectedOption === 1,
                        [styles['tab-button-swipe--right-2']]: selectedOption - previousOption === 2,
                        [styles['tab-button-swipe--left-2']]: previousOption - selectedOption === 2
                      })}
                    ></div>
                  )}
                  {selectedOption === i && isAutoAdvancing && (
                    <div className={styles['tab-button-progress']}>
                      <div className={styles['tab-button-progress-inner']} />
                    </div>
                  )}
                  <div className={styles['tab-button-inner']}>
                    <div className={styles['tab-button-text']}>
                      <p className={styles['tab-button-number']}>{i + 1}</p>
                      <h3>{option.h3}</h3>
                    </div>
                  </div>
                </button>
                <div
                  key={option.h3}
                  className={classnames(styles['mobile-panel-text'], {
                    [styles['mobile-panel-text-selected']]: selectedOption === i
                  })}
                >
                  <ul>
                    {option.bulletpoints.map((bullet) => (
                      <li key={bullet.text}>
                        {bullet.icon ? (
                          <span className={styles['bullet-icon']}>{bullet.icon}</span>
                        ) : (
                          <CheckIcon fontSize="small" />
                        )}
                        {bullet.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        {isSmall && (
          <>
            <Video
              vimeoId={1012057413}
              width={300}
              previewText="Hear how it works from our co&#8209;founder"
              placeholder={Placeholder.src}
              loadingImg={LoadingImg.src}
              className={styles['video']}
              position="HOWITWORKS V2"
              location="Homepage"
            />
            <Button
              variant="primary"
              className={classnames(styles['craft-trip'], styles['craft-trip--mobile'])}
              href="/request-to-travel"
              onClick={() => {
                elementClicked({
                  actionOutcome: 'INTERNALLINK',
                  outboundUrl: routes.requestForm.index,
                  webElement: {
                    elementType: 'Button',
                    location: 'Homepage',
                    name: 'HIW CTA',
                    position: 'HOWITWORKS V2',
                    text: 'Craft your trip'
                  }
                });
              }}
            >
              Craft your trip
            </Button>
          </>
        )}
        <div className={styles['bottom-panel']}>
          {PROCESS_OPTIONS.map((option, i) => {
            const { h3, bulletpoints } = option;
            return (
              i === selectedOption && (
                <div className={styles['bottom-panel-text']} key={h3}>
                  <ul>
                    {bulletpoints.map((bullet) => (
                      <li key={bullet.text}>
                        {bullet.icon ? (
                          <span className={styles['bullet-icon']}>{bullet.icon}</span>
                        ) : (
                          <CheckIcon fontSize="small" />
                        )}
                        {bullet.text}
                      </li>
                    ))}
                  </ul>
                  <Button
                    variant="primary"
                    className={classnames(styles['craft-trip'], styles['craft-trip--desktop'])}
                    href="/request-to-travel"
                    onClick={() => {
                      elementClicked({
                        actionOutcome: 'INTERNALLINK',
                        outboundUrl: routes.requestForm.index,
                        webElement: {
                          elementType: 'Button',
                          location: 'Homepage',
                          name: `Step ${i + 1}`,
                          position: 'HOWITWORKS V2',
                          text: 'CTA'
                        }
                      });
                    }}
                  >
                    Craft your trip
                  </Button>
                </div>
              )
            );
          })}
          {!isSmall && (
            <Video
              vimeoId={1012057413}
              width={400}
              previewText="Hear how it works from our co&#8209;founder"
              placeholder={Placeholder.src}
              loadingImg={LoadingImg.src}
              className={styles['video']}
              position="HOWITWORKS V2"
              location="Homepage"
            />
          )}
        </div>
      </div>
    </div>
  );
}
