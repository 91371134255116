import styles from './HowItWorks.module.scss';

import { Process, FrequentlyAskedQuestions } from '@components/Homepage/HowItWorksV1';
import Button from '@components/shared/Button/Button';
import routes from '@constants/routes';
import { elementClicked } from '@utils/sendEvent';

export function HowItWorks() {
  return (
    <div className={styles['hiw-container']}>
      <div className={styles['hiw-container__background-gradient']} />
      <Process />
      <FrequentlyAskedQuestions />
      <Button
        variant="primary"
        className={styles['craft-trip']}
        href="/request-to-travel"
        onClick={() => {
          elementClicked({
            actionOutcome: 'INTERNALLINK',
            outboundUrl: routes.requestForm.index,
            webElement: {
              elementType: 'Button',
              location: 'Homepage',
              name: 'HIW CTA',
              position: 'HOWITWORKS V2',
              text: 'Craft your trip'
            }
          });
        }}
      >
        Craft your trip
      </Button>
    </div>
  );
}
