import { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import classNames from 'classnames';

import styles from './Video.module.scss';
import VideoModal from './VideoModal';

import Icon from '@components/shared/Icon/Icon';
import { elementClicked } from '@utils/sendEvent';

export function Video({
  vimeoId,
  width = 400,
  previewText,
  placeholder,
  loadingImg,
  className,
  position = '',
  location = ''
}: {
  vimeoId: number;
  width?: number;
  previewText: string;
  placeholder?: string;
  loadingImg?: string;
  className?: string;
  position?: string;
  location?: string;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVimeoReady, setIsVimeoReady] = useState(false);

  const handlePause = () => {
    setIsPlaying(false);
    setIsVimeoReady(false);
  };

  const handlePlay = () => {
    setIsPlaying(true);
    elementClicked({
      actionOutcome: 'PLAY',
      outboundUrl: 'null',
      webElement: {
        elementType: 'Button',
        location,
        name: 'Video',
        position,
        text: previewText
      }
    });
  };

  return (
    <div className={classNames(styles['video__frame'], className)} onClick={isPlaying ? () => {} : handlePlay}>
      <img
        src={placeholder ? placeholder : 'img/homepage/globe-go-elsewhere.svg'}
        alt="Video placeholder"
        className={styles['video__placeholder']}
      />
      {previewText && (
        <div className={styles['video__title']}>
          <h4 className={styles['video__title__text']}>{previewText}</h4>
        </div>
      )}
      <div className={styles['video__button']}>
        <Icon
          icon={isPlaying ? 'icon-24-pause' : 'icon-32-play'}
          className={isPlaying ? styles['video__button__svg-pause'] : styles['video__button__svg-play']}
        />
      </div>
      <VideoModal isOpen={isPlaying} onClose={handlePause}>
        <div className={styles['video__inner-frame']}>
          {!isVimeoReady && isPlaying && (
            <img
              src={loadingImg ? loadingImg : 'img/homepage/globe-go-elsewhere.svg'}
              alt="Video loading image"
              className={styles['video__placeholder']}
            />
          )}
          <Vimeo
            className={styles['video__vimeo']}
            video={vimeoId}
            autoplay
            showTitle={false}
            showPortrait={false}
            showByline={false}
            loop
            width={width}
            paused={!isPlaying}
            onPlay={() => setIsVimeoReady(true)}
            textTrack="en-x-autogen"
          />
        </div>
      </VideoModal>
    </div>
  );
}
