/* eslint-disable import/no-unresolved */
// adding disable rule for swiper import
import React, { useEffect, useRef } from 'react';
import { useMediaQuery, Button, Rating } from '@mui/material';
import { register } from 'swiper/element/bundle';
import { useRouter } from 'next/router';

import styles from './GoogleReviews.module.scss';

import theme from '@styles/theme';
import routes from '@constants/routes';
import { mockGoogleData } from '@constants/constants';
import { elementClicked } from '@utils/sendEvent';

//Top CTA can be hidden on desktop if there's already a CTA right above this section.

export default function GoogleReviews({ reviews, hideTopCta }) {
  const router = useRouter();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const swiperRef = useRef(null);

  useEffect(() => {
    register();

    const params = {
      slidesPerView: 1,
      slidesPerGroup: 1,
      centerslides: true,
      keyboard: true,
      mousewheel: {
        forceToAxis: true
      },
      navigation: true,
      pagination: {
        enabled: true,
        clickable: true
      },
      grabCursor: true,
      freeMode: {
        enabled: true
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 16
        },
        600: {
          slidesPerView: 1.4,
          slidesPerGroup: 2,
          spaceBetween: 10
        },
        900: {
          slidesPerView: 2.2,
          slidesPerGroup: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 2.9,
          slidesPerGroup: 3,
          spaceBetween: 10
        },
        1440: {
          slidesPerView: 3.3,
          slidesPerGroup: 4,
          spaceBetween: 10
        },
        1720: {
          slidesPerView: 4.2,
          slidesPerGroup: 4
        }
      },

      injectStyles: [
        `
        .swiper {
          overflow: visible;
          height: 350px;
          width: 100%;
          margin-bottom: 80px;
          margin: 0 auto;
          
          /* Smaller devices (300px) */
          @media (min-width: 300px) {
              width: 380px;
              margin-bottom: 0;
          }
          
          /* Small devices (600px) */
          @media (min-width: 600px) {
              width: 560px;
          }
          
          /* Medium devices (900px) */
          @media (min-width: 900px) {
              width: 860px;
          }
          
          /* Large devices (1200px) */
          @media (min-width: 1200px) {
              width: 1160px;
          }
          
          /* Extra large devices (1440px) */
          @media (min-width: 1440px) {
              width: 1320px;
          }

          /* Extra large devices (1720) */
          @media (min-width: 1720px) {
              width: 1680px;
          }
        }

        .swiper-pagination {
          @media (max-width: 760px) {
            display: none;
          }
        }

        .swiper-button-next, .swiper-button-prev{
          position:absolute;
          height: 32px;
          top: 310px;
          cursor: default;
          color: #5C5766;

          @media (min-width: 760px) {
            display: none!important;
          }
        }

        .swiper-pagination-bullets.swiper-pagination-horizontal {
          top: 290px !important;
        }

        .swiper-button-prev{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #DCD8E7;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          position: absolute;
          left: 150px;
        }

        .swiper-button-next{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #DCD8E7;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          align-content: center;
          text-align: center;
          position: absolute;
          right: 150px;
        }

        .swiper-button-next > svg, .swiper-button-prev > svg{
          height: 14px;
          width: 14px;
        }

        .swiper-pagination-bullet-active {
          background-color: #8A8597;
        }
    
        `
      ]
    };

    if (isSmall) {
      params.freeMode.enabled = false;
    } else {
      params.freeMode.enabled = true;
    }

    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();
  }, [isMedium, isSmall, isLarge]);

  const cta = () => {
    elementClicked({
      actionOutcome: 'INTERNALLINK',
      outboundUrl: routes.requestForm.index,
      webElement: {
        elementType: 'button',
        location: 'Google Reviews',
        name: 'Craft your trip',
        position: '0',
        text: 'Craft your trip'
      }
    });
    router.push(routes.requestForm.index);
  };

  return (
    <div className="full-page-container overflow-visible" style={{ marginBottom: isSmall ? -10 : 32 }}>
      <div className={styles['container-headings']}>
        <div>
          <h2>Don&rsquo;t listen to us, {isSmall ? <br /> : ''} listen to them</h2>
          <p className="subheading">We love our travelers. Thankfully, the feeling&rsquo;s mutual.</p>
        </div>
        <div className="google-component">
          <div className="icon-wrapper">
            <img className="google-icon" src="/icons/icon-google.svg" alt="google icon" />
          </div>
          <div className="reviews-stats">
            <div className="double-section">
              <span className="overall-rating">{reviews.rating || mockGoogleData.rating}</span>
              <Rating
                name="rating"
                value={reviews.rating || mockGoogleData.rating}
                readOnly
                precision={0.5}
                max={5}
                sx={{ top: 4 }}
              />
            </div>
            <div className="reviews-link">
              <a
                onClick={() =>
                  elementClicked({
                    actionOutcome: 'EXTERNALLINK',
                    outboundUrl: mockGoogleData.reviewsLink,
                    webElement: {
                      elementType: 'anchor',
                      location: 'Google Reviews',
                      name: 'See all Reviews',
                      position: '0',
                      text: 'See all Reviews'
                    }
                  })
                }
                href={mockGoogleData.reviewsLink}
                target="_blank"
                rel="noreferrer"
              >
                See all reviews
              </a>
              <span className="total-reviews">({reviews.userRatingCount || mockGoogleData.totalReviews})</span>
            </div>
          </div>

          {!isLarge && !hideTopCta && (
            <Button variant="contained" size="large" onClick={cta} sx={{ minWidth: 'auto', marginLeft: 2 }}>
              Craft your trip
            </Button>
          )}
        </div>
      </div>

      <swiper-container style={{ maxWidth: '100vw', width: '100%', overflow: 'hidden' }} init="false" ref={swiperRef}>
        {mockGoogleData.reviews.map((review, i) => (
          <swiper-slide key={`review-${i}`} virtualIndex={i}>
            <div className={styles['review-wrapper']}>
              <img src="/icons/icon-quotes.svg" alt="quotation mark" />
              <div className={styles['review']}>
                <p>{review.text}</p>
                <Rating
                  name="rating"
                  value={review.rating || mockGoogleData.rating}
                  readOnly
                  precision={0.5}
                  max={5}
                  sx={{ top: 4 }}
                />
                <div>
                  <span>{review.author}</span>
                  {review.source && (
                    <>
                      <div
                        style={{
                          background: '#5C5766',
                          borderRadius: '50%',
                          display: 'inline-block',
                          height: '3px',
                          marginLeft: '8px',
                          marginRight: '8px',
                          marginBottom: '3px',
                          width: '3px'
                        }}
                      ></div>
                      <span>{review.source}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </swiper-slide>
        ))}
      </swiper-container>
      {(isSmall || isMedium || isLarge) && (
        <div className="page-container">
          <Button
            variant="contained"
            size="large"
            onClick={cta}
            className={styles['cta-button']}
            sx={{ mb: 4, mt: 1, width: '90%', mr: 'auto', ml: '5%' }}
          >
            Craft your trip
          </Button>
        </div>
      )}
    </div>
  );
}
