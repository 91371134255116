import { useCallback, useState } from 'react';

import { makeWriteKey, sentryEnvironment } from '@constants/constants';

export default function useMonarch() {
  const [monarchResponses, setMonarchResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Keep track of Monarch responses in the case of calling multiple Monarch rules
  const handleMonarchResponse = useCallback(
    (res: any) => {
      const prev = monarchResponses;
      const next = [...prev, res];

      setMonarchResponses(next);
    },
    [monarchResponses]
  );

  const monarch = ({
    rid,
    payload = {},
    params = {},
    callback
  }: {
    rid: string;
    payload?: any;
    params?: any;
    callback: Function;
  }) => {
    // Check that callback is present and a function.
    if (typeof callback !== 'function') {
      throw new Error(`Callback of type '${typeof callback}' is not a function`);
    }

    setIsLoading(true);

    const withCallback = (error: Error, response: any) => {
      handleMonarchResponse({ error, response });
      callback(error, response);
      setIsLoading(false);
    };

    window.cohesion('monarch:ready', () => {
      //For development environments, we need to manually override the writeKey to send to our dev Make source
      window.monarch(
        'rule',
        rid,
        payload,
        sentryEnvironment == 'production' ? params : { ...params, writeKey: makeWriteKey },
        withCallback
      );
    });
  };

  return { monarch, monarchResponses, isLoading };
}
