import { useState, useRef } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import styles from './FrequentlyAskedQuestions.module.scss';

import Button from '@components/shared/Button/Button';
import { FAQ } from '@constants/homepage/HowItWorksV1';
import routes from '@constants/routes';
import Icon from '@components/shared/Icon/Icon';
import theme from '@styles/theme';
import { elementClicked } from '@utils/sendEvent';
import InclusionsExclusions from '@components/RequestForm/Budget/InclusionsExclusions';

export function FrequentlyAskedQuestions() {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDropdown, setOpenDropdown] = useState(-1);
  const faqRef = useRef<HTMLDivElement>(null);

  const handleFaqClick = (i: number, h3: string) => () => {
    //Toggle dropdown, scroll to top of faqs, and send cohesion events
    if (openDropdown === i) {
      setOpenDropdown(-1);
    } else {
      setOpenDropdown(i);
      //Only need dropdown on mobile
      if (isSmall) {
        setTimeout(() => {
          //Need to set a timeout so the faq dropdown has time to expand
          if (faqRef) {
            const y = faqRef.current.getBoundingClientRect().top + window.scrollY - (isSmall ? 72 : 80);
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        }, 300);
      }
      elementClicked({
        actionOutcome: 'EXPAND',
        outboundUrl: 'null',
        webElement: {
          elementType: 'Button',
          location: 'Homepage',
          name: `FAQ ${i + 1}`,
          position: 'HOWITWORKS V2',
          text: h3
        }
      });
    }
  };

  return (
    <div className={styles['faq-container']} ref={faqRef}>
      <h2 className={styles['faq-container__header']}>{isSmall ? 'FAQ' : 'Frequently asked questions'}</h2>
      <div className={styles['text-container-wrapper']}>
        <div className={styles['text-container-right']}>
          {FAQ.map((q, i) => {
            const { h3, p } = q;
            return (
              <div key={h3} id={`faq-${i}`}>
                <button className={styles['dropdown']} type="button" onClick={handleFaqClick(i, h3)}>
                  <div
                    className={classnames(styles['dropdown-title'], {
                      [styles['dropdown-title--open']]: openDropdown === i
                    })}
                  >
                    <h4>{h3}</h4>
                    <Icon
                      icon="arrow-up"
                      size={16}
                      style={openDropdown === i ? { transform: 'rotate(0)' } : { transform: 'rotate(180deg)' }}
                    />
                  </div>
                </button>
                <div
                  className={classnames(
                    styles['dropdown-content'],
                    openDropdown === i ? styles['dropdown-open'] : styles['dropdown-closed']
                  )}
                >
                  {p.map((text) => (
                    <p key={text}>{text}</p>
                  ))}
                  {i === 0 && openDropdown === i && (
                    <div className={styles['inclusions-exclusions']}>
                      <InclusionsExclusions faqSlot={null} />
                    </div>
                  )}
                  {openDropdown === i && isSmall && (
                    <Button
                      variant="primary"
                      className={styles['craft-trip']}
                      href="/request-to-travel"
                      onClick={() => {
                        elementClicked({
                          actionOutcome: 'INTERNALLINK',
                          outboundUrl: routes.requestForm.index,
                          webElement: {
                            elementType: 'Button',
                            location: 'Homepage',
                            name: `FAQ ${i + 1}`,
                            position: 'HOWITWORKS V2',
                            text: 'CTA'
                          }
                        });
                      }}
                    >
                      Craft your trip
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
